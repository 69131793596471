import Link from "next/link";
import * as React from "react";
import { CommonHead } from "@components/CommonHead";
import CommonLayout from "@components/CommonLayout";
import { paths } from "@libs/paths";
import styles from "./error.module.css";

const Custom404: React.FC = () => {
  return (
    <CommonLayout noModal>
      <CommonHead
        title={"404"}
        description={"その日、お店は スタジアムになる。"}
        ogImage={""}
      />

      <div className={styles.error}>
        <main className={styles.main}>
          <div className="l-main__innerWidth">
            <h2 className={styles.errorTitle}>
              お探しのページが見つかりませんでした
            </h2>
            <div className={styles.errorLink}>
              <Link href={paths.home} className={styles.link}>
                Fanstaトップへ
              </Link>
            </div>
          </div>
        </main>
      </div>
    </CommonLayout>
  );
};

export default Custom404;
